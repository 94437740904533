import * as React from "react";
import type * as LabelPrimitive from "@radix-ui/react-label";

import { cn } from "~/lib/utils";
import { Label } from "~/components/ui/label";

type FormItemContextValue = {
  inputId: string;
  descriptionId: string;
  errorId: string;
  errors?: string[];
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

export const useFormItemContext = () => {
  const context = React.useContext(FormItemContext);
  return context || {};
};

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & { errors?: string[] }>(
  ({ className, errors, ...props }, ref) => {
    const inputId = React.useId();
    const descriptionId = React.useId();
    const errorId = React.useId();

    return (
      <FormItemContext.Provider value={{ inputId, descriptionId, errorId, errors }}>
        <div
          ref={ref}
          className={cn("w-auto space-y-2 rounded-md p-2", errors?.length && "bg-red-50", className)}
          {...props}
        />
      </FormItemContext.Provider>
    );
  },
);
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const form = useFormItemContext();

  return (
    <Label
      ref={ref}
      className={cn(form.errors?.length && "text-red-500 dark:text-red-900", "mr-2", className)}
      htmlFor={form.inputId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { descriptionId } = React.useContext(FormItemContext);
    return (
      <div
        ref={ref}
        id={descriptionId}
        className={cn("hyphens-auto text-sm text-slate-500 dark:text-slate-400", className)}
        {...props}
      />
    );
  },
);
FormDescription.displayName = "FormDescription";

const FormError = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, children, ...props }, ref) => {
    const form = React.useContext(FormItemContext);
    return (
      <p
        id={form.errorId}
        ref={ref}
        aria-live={form.errors?.length ? "assertive" : "off"}
        className={cn(
          `text-sm font-medium text-red-500 dark:text-red-900 ${!form.errors?.length && "invisible"}`,
          className,
        )}
        {...props}
      >
        {form.errors?.map((error) => <span key={error}>{error}</span>) || <span>&nbsp;</span>}
      </p>
    );
  },
);
FormError.displayName = "FormMessage";

export { FormItem, FormLabel, FormDescription, FormError };
